var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.article)?_c('div',{staticClass:"ml-3 mb-2 rounded main-container"},[_c('div',{staticClass:"box-header"},[_vm._v("Article")]),_c('div',{staticClass:"box-body"},[_c('div',{staticClass:"box-content"},[_c('h1',[_c('router-link',{attrs:{"to":{ name: 'article', params: { id: _vm.article.id } }}},[_vm._v(_vm._s(_vm.article.title))])],1),_c('span',{staticClass:"box-content-info"},[_c('router-link',{attrs:{"to":{
                        name: 'user',
                        params: { id: _vm.article.author.id },
                    }}},[_vm._v(_vm._s(_vm.article.author.username))]),_vm._v(" / "+_vm._s(_vm._f("formatTimestamp")(_vm.article.created))+" ")],1),_c('div',{staticClass:"box-content-ingress",domProps:{"innerHTML":_vm._s(_vm.article.contentBbCode())}}),_c('div',{staticClass:"box-content-body",domProps:{"innerHTML":_vm._s(_vm.article.bodyBbCode())}}),_c('div',{staticClass:"box-content-footer"},[_c('i',{staticClass:"fas fa-comments mr-2"}),_vm._v(" "+_vm._s(_vm.article.num_comments)+" "),_c('div',{staticClass:"float-right"},[_c('span',{staticClass:"pointer mr-3 p-0",attrs:{"title":"Comment on article"},on:{"click":function($event){$event.preventDefault();return _vm.scrollToCommentForm()}}},[_c('i',{staticClass:"fas fa-reply"})]),(_vm.$can('edit_article'))?_c('router-link',{attrs:{"to":{
                            name: 'adminEditArticle',
                            params: { id: _vm.article.id },
                        },"title":"Edit article"}},[_c('i',{staticClass:"fas fa-pen mr-2"})]):_vm._e()],1)])]),_c('comment-list',{staticClass:"mt-2",attrs:{"header":"Comments","type":"article","id":_vm.article.id}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }